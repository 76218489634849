import React from "react";

const OptionCard = ({
  sessionOption,
  setSessionOption,
  hasPhysical,
  hasAnyAccess,
  hasAnyWorkshop,
  hasAllAccess,
}) => {
  // hasAllAccess, show no options
  // hasPhysical and !hasAnyWorkshop, show addOnMndDiscWorkshops
  // hasAnyWorkshop and !hasPhysical, show mainCongress
  // has nothing, show all options but no addOnMndDiscWorkshops
  const options = hasAllAccess
    ? null
    : hasPhysical && !hasAnyWorkshop
    ? [
        {
          id: "addOnMndDiscWorkshops",
          label: "Add-on the Day 1 Workshop",
        },
      ]
    : hasAnyWorkshop && !hasPhysical
    ? [
        {
          id: "mainCongress",
          label: "Day 2 and Day 3 Main Congress only",
        },
      ]
    : [
        {
          id: "fullCongress",
          label: "Full Congress including Day 1 Workshop",
        },
        {
          id: "mainCongress",
          label: "Day 2 and Day 3 Main Congress only",
        },
        // {
        //   id: "day1MndOnly",
        //   label:
        //     "Day 1 Workshop only (Australia and New Zealand MND Research Symposium/12th MND Australia Care Forum)",
        // },
      ];

  if (!options) {
    return (
      <div className="text-center">
        <h5>You have registered for all sessions!</h5>
      </div>
    );
  }

  return (
    <div className="session-selection d-flex flex-wrap flex-column align-items-center">
      <h5>Which session would you like to join?</h5>
      <div className="button-group d-flex flex-wrap justify-content-center">
        {options?.map((option) => (
          <button
            key={option.id}
            type="button"
            className={`btn w-75 m-2 ${
              sessionOption === option.id
                ? "btn-primary"
                : "btn-outline-primary"
            } `}
            onClick={() => setSessionOption(option.id)}
          >
            {option.label}
          </button>
        ))}
      </div>
      <p className="mt-2">
        *Please note that delegate is required to register for the Day 2 and Day
        3 Main Congress to join the PACTALS Workshop on Day 1
      </p>
    </div>
  );
};

export default OptionCard;
